import request from 'utils/request';

const api = {
  OPERATION_LOG: '/api/close/rule/selectOperationLogs',
};

export function fetchOperationLogPage(query) {
  return request({
    url: api.OPERATION_LOG,
    method: 'get',
    params: query,
  });
}
