<template>
  <div class="productOverall">
    <!-- <div class="main-Title bgff"><h2>Offer Change log</h2></div> -->
    <div class="mainBox">
      <el-card shadow="hover" class="mb5 list-filter">
        <el-form :model="filter" label-width="70px" size="mini">
          <el-row>
            <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="4">
              <el-form-item label="user" class="mb5">
                <el-select
                  v-model="filter.user"
                  filterable
                  placeholder="Please select"
                  class="w100"
                >
                  <el-option
                    v-for="item in options.subRule"
                    :key="item.accountId"
                    :label="item.username"
                    :value="item.username"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="4">
              <el-form-item label="date" class="mb5" label-width="50px">
                <!-- :picker-options="pickerOptions" -->
                <el-date-picker
                  v-model="filter.date"
                  type="datetimerange"
                  range-separator="-"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  format="yyyy-MM-dd HH:mm:ss"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  :default-time="['00:00:00', '23:59:59']"
                  class="w100"
                  :clearable="false"
                ></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="5" :lg="5" :xl="3">
              <el-form-item label="url" class="mb5" label-width="70px">
                <el-input v-model="filter.url"></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="6" :lg="5" :xl="3" :offset="1">
              <el-form-item label="requParam" class="mb5" label-width="80px">
                <el-input v-model="filter.requParam"></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="5" :lg="4" :xl="3">
              <el-form-item label="body" class="mb5" label-width="70px">
                <el-input v-model="filter.body"></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="2" :lg="2" :xl="2" align="right">
              <el-form-item class="mb5" label-width="0">
                <el-button type="primary" :loading="loading.list" @click="getList(1)"
                  >Search</el-button
                >
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-card>
      <el-card shadow="hover">
        <el-table
          :data="list"
          border
          stripe
          style="width:100%;"
          size="mini"
          v-loading="loading.list"
        >
          <el-table-column label="id" prop="id"></el-table-column>
          <el-table-column label="url" prop="url"></el-table-column>
          <el-table-column label="method" prop="method"></el-table-column>
          <el-table-column label="ip" prop="ip"></el-table-column>
          <el-table-column label="requParam" prop="requParam"></el-table-column>
          <el-table-column label="user" prop="user"></el-table-column>
          <el-table-column label="resp" prop="resp" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column label="body" prop="body" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column label="createTime" prop="createTime"></el-table-column>
          <el-table-column label="finishTime" prop="finishTime"></el-table-column>
        </el-table>
        <div class="align-c">
          <pagination
            class="block pagePanel"
            :pageTotal="total"
            @handleSizeChange="handleSizeChange"
            @handleCurrentChange="handleCurrentChange"
            :pageSize="pageSize"
            :currentPage="currentPage"
          ></pagination>
        </div>
      </el-card>
    </div>
  </div>
</template>
<script>
  import OperationLogCon from '../controllers/product/OperationLog';
  export default {
    name: 'OperationLogCon',
    ...OperationLogCon,
  };
</script>
<style lang="scss" scoped>
  .cor337ab7 {
    color: #337ab7;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
</style>
